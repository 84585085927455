import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout/Layout";
import Seo from "../../components/seo/Seo";
import Sticky from "react-stickynode";
import UtilImage from "../../components/util/UtilImage";
import RichText from "../../components/text/RichText";
import { formatPrice } from "../../utils/format-price";
import { AddToCart } from "../../components/add-to-cart/AddToCart";
import { StoreContext } from "../../context/store-context";
import "./product.scss";
export default function Product({ data: { product } }) {
  const { client, checkout } = React.useContext(StoreContext);

  const afs = product.sourceData.availableForSale;
  const [available, setAvailable] = React.useState(afs);
  const price = formatPrice("GBP", product.maxVariantPrice);

  let variantId = product.variants[0].shopifyVariantID;
  const inCart = checkout.lineItems.some(
    (product) => variantId === product.variant.id
  );
  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === variantId
          ) ?? [];

        if (result.length > 0) {
          setAvailable(result[0].available);
        }
      });
    },
    [variantId, client.product]
  );
  React.useEffect(() => {
    checkAvailablity(product.shopifyId);
  }, [variantId, checkAvailablity, product.shopifyId]);
  return (
    <Layout>
      <Seo title={product.title} />
      <div className="flex flex-col mb-8 text-lg leading-none md:text-2xl md:flex-row">
        <div className="flex flex-col -mt-8  md:pr-12 md:w-6/12 sticky-container md:-mt-36">
          <Sticky
            className="hidden sidebar md:block"
            // activeClass="sticky-container"
            innerActiveClass="sticky-container"
            // releasedClass="sticky-container"
          >
            <div className="pb-12 leading-none normal-case md:pt-36">
              <figure className="w-full my-8 md:my-12 md:hidden">
                {product.cover && (
                  <UtilImage
                    image={product.cover.cover.asset.gatsbyImageData}
                    alt={product.title}
                    className="object-contain w-full h-full cover"
                    style={{
                      position: "relative",
                    }}
                    objectFit="contain"
                  />
                )}
              </figure>
              <header className="flex">
                <div>
                  {product.title && <h1>{product.title}</h1>}
                  <div className="description">
                    {product.shortDescription &&
                      product.shortDescription.map((child) => {
                        child.markDefs = [];
                        return (
                          <RichText
                            key={child._key}
                            className="mt-5 normal-case"
                            blocks={child}
                          />
                        );
                      })}
                  </div>
                </div>
                {available ? (
                  <nav className="flex flex-col items-end w-40 ml-auto text-right normal-case ">
                    <span>{price}</span>

                    {!inCart ? (
                      <AddToCart
                        variantId={variantId}
                        quantity={1}
                        available={available}
                        className="pb-0 leading-none uppercase border-b-2 border-transparent  hover:border-red"
                      />
                    ) : (
                      <span className="uppercase border-b-2 border-transparent  whitespace-nowrap">
                        ✓ In cart
                      </span>
                    )}
                  </nav>
                ) : (
                  <nav className="flex flex-col items-end ml-auto text-right uppercase">
                    <span>Sold out</span>
                    <a
                      href="mailto:info@lookbooks.store"
                      className="pb-0 leading-none uppercase border-b-2 border-transparent  hover:border-red"
                    >
                      Enquire
                    </a>
                  </nav>
                )}
              </header>
              <div className="mt-4">
                {product.description &&
                  product.description.map((child) => {
                    child.markDefs = [];
                    return <RichText key={child._key} blocks={child} />;
                  })}
              </div>
            </div>
          </Sticky>

          <div className="pb-12 leading-none normal-case md:hidden md:pt-36">
            {product.cover && (
              <figure className="w-full my-8 md:my-12 md:hidden">
                <UtilImage
                  image={product.cover.cover.asset.gatsbyImageData}
                  alt={product.title}
                  className=" object-contain w-full h-full cover"
                  style={{
                    position: "relative",
                  }}
                  objectFit="contain"
                />
              </figure>
            )}
            <header className="flex">
              <div>
                {product.title && <h1>{product.title}</h1>}
                <div className="mt-1 description">
                  {product.shortDescription &&
                    product.shortDescription.map((child) => {
                      child.markDefs = [];
                      return (
                        <RichText
                          key={child._key}
                          className="mt-5 normal-case"
                          blocks={child}
                        />
                      );
                    })}
                </div>
              </div>
              {available ? (
                <nav className="flex flex-col items-end ml-auto text-right normal-case">
                  <span>{price}</span>

                  {!inCart ? (
                    <AddToCart
                      variantId={variantId}
                      quantity={1}
                      available={available}
                      className="pb-0 leading-none uppercase border-b-2 border-transparent  hover:border-red"
                    />
                  ) : (
                    <span className="uppercase border-b-2 border-transparent  whitespace-nowrap">
                      ✓ In cart
                    </span>
                  )}
                </nav>
              ) : (
                <nav className="flex flex-col items-end ml-auto text-right uppercase">
                  <span>Sold out</span>
                  <a
                    href="mailto:info@lookbooks.store"
                    className="pb-0 leading-none uppercase border-b-2 border-transparent  hover:border-red"
                  >
                    Enquire
                  </a>
                </nav>
              )}
            </header>
            <div className="mt-4">
              {product.description &&
                product.description.map((child) => {
                  child.markDefs = [];
                  return <RichText key={child._key} blocks={child} />;
                })}
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-1 w-full px-12 -mt-12 md:-ml-7 md:px-0">
          {product.cover && (
            <figure className="items-center justify-center hidden w-full my-8  md:my-12 h-112 md:flex">
              <UtilImage
                image={product.cover.cover.asset.gatsbyImageData}
                alt={product.title}
                className=" object-contain object-center w-full h-full cover"
                style={{
                  position: "relative",
                }}
                objectFit="contain"
              />
            </figure>
          )}
          {product.images.map((image) => {
            return (
              <figure className="w-full my-8 md:my-12 h-112" key={image._key}>
                <UtilImage
                  image={image.asset.gatsbyImageData}
                  alt={product.title}
                  className=" object-contain w-full h-full"
                  style={{
                    position: "relative",
                  }}
                  objectFit="contain"
                />
              </figure>
            );
          })}
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($id: String!) {
    product: sanityShopifyProduct(id: { eq: $id }) {
      ...ProductCard
    }
  }
`;
