import * as React from "react"
import { StoreContext } from "../../context/store-context"

export function AddToCart({ variantId, quantity, className }) {
  const { addVariantToCart, loading } = React.useContext(StoreContext)

  const [load, setLoad] = React.useState(false)
  const [clicked, setClicked] = React.useState(false)

  function addToCart(e) {
    e.preventDefault(e)
    setClicked(true)
    addVariantToCart(variantId, quantity)
  }

  React.useEffect(() => {
    if (clicked) {
      setLoad(loading)
    }
  }, [loading, clicked])
  return (
    <button
      type="submit"
      className={className}
      onClick={addToCart}
      disabled={load}
    >
      {!load ? "Buy" : "Adding..."}
    </button>
  )
}
